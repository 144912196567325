import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { WidgetLayout } from "../../../../components/WidgetLayout";

import { useGetQRCode } from "../../hooks/useGetQRCode";
import { useOnBoardingContext } from "../../hooks/useOnBoardingContext";
import { FC, useEffect, useState } from "react";
import { useCheckUser } from "../../hooks/useCheckEmail";
import useLocalStorage from "../../../ui/hooks/useLocalStorage";
import {
  AppleStore,
  GooglePlay,
  AppleStoreQR,
  GooglePlayQR,
} from "../../../../assets/icons";
import { APP_STORE_URL, GP_URL, MOBILE_APP_URL } from "../../models";

type TDeviceOs = "Android" | "iOS" | "Other";

export const QRScreen: FC<{ email: string | undefined }> = ({ email }) => {
  const { requestId, requestLoading, requestError } = useOnBoardingContext();
  const [data, qrLoading, qrError] = useGetQRCode(requestId || undefined);
  const [userEmail, setUserEmail] = useLocalStorage("email", null);
  const [user, userLoading, userError] = useCheckUser(userEmail);
  const [deviceOs, setDeviceOs] = useState<TDeviceOs>("Other");

  const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      setDeviceOs("Android");
    } else if (/iPad|iPhone|iPod/.test(ua)) {
      setDeviceOs("iOS");
    } else {
      setDeviceOs("Other");
    }
  };

  useEffect(() => {
    getMobileOS();
  }, []);

  const onPressToVerificartion = () => {
    if (deviceOs !== "Other") {
      try {
        window.location.replace(`${MOBILE_APP_URL}verificationId=${requestId}`);
      } catch (e) {
        if (deviceOs === "Android") {
          setTimeout(() => {
            window.location.replace(GP_URL);
          }, 1000);
        } else if (deviceOs === "iOS") {
          setTimeout(() => {
            window.location.replace(APP_STORE_URL);
          }, 1000);
        }
      }
    }
  };

  useEffect(() => {
    if (email || user?.email) {
      setUserEmail(email || user.email);
    }
  }, [user, setUserEmail, email]);

  const loading = qrLoading || requestLoading || userLoading;
  const error = qrError || requestError || userError;

  return (
    <WidgetLayout title='Complete your KYC'>
      <Stack
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        spacing={3}
      >
        {loading && <CircularProgress size={50} />}
        {error !== null && (
          <Alert severity='error'>{error.message || error.toString()}</Alert>
        )}
        {data && (
          <>
            <Box
              sx={{
                minWidth: "345px",
                padding: "16px",
                background: "#fff",
                boxShadow:
                  "0px 14.770000457763672px 40.39707946777344px 0px #0000000a, 0px 6.139999866485596px 16.778879165649414px 0px #00000008, 0px 2.2200000286102295px 6.068591594696045px 0px #00000006",
                borderRadius: "12px",
              }}
            >
              <Stack
                direction={deviceOs === "Other" ? "row" : "column"}
                spacing={2}
                alignItems='center'
              >
                <Box>
                  <Typography
                    textAlign={deviceOs === "Other" ? "initial" : "center"}
                    fontWeight='bold'
                  >
                    Already on OneBoard?
                  </Typography>
                  <Typography variant='body2'>
                    {deviceOs === "Other"
                      ? "Scan QR code via OneBoard app"
                      : "Press button "}
                    {deviceOs === "Other" ? <br /> : null}
                    to complete your KYC in two clicks.
                  </Typography>
                </Box>
                {deviceOs === "Other" ? (
                  <QRCode value={data.data} size={160} />
                ) : (
                  <Button
                    fullWidth
                    variant='contained'
                    onClick={onPressToVerificartion}
                  >
                    Go to App
                  </Button>
                )}
              </Stack>
            </Box>
            <Stack alignItems='center' justifyContent='center'>
              <Typography fontWeight='bold' textAlign='center'>
                or
              </Typography>
            </Stack>
            <Box
              sx={{
                minWidth: "345px",
                padding: "16px",
                background: "#fff",
                boxShadow:
                  "0px 14.770000457763672px 40.39707946777344px 0px #0000000a, 0px 6.139999866485596px 16.778879165649414px 0px #00000008, 0px 2.2200000286102295px 6.068591594696045px 0px #00000006",
                borderRadius: "12px",
              }}
            >
              <Stack sx={{ marginBottom: 2 }} alignItems='center'>
                <Typography fontWeight='bold'>Register with app</Typography>
              </Stack>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='center'
              >
                <Stack
                  sx={{ marginRight: 4 }}
                  direction='column'
                  alignItems='center'
                >
                  {deviceOs === "Other" ? (
                    <Box sx={{ marginBottom: 1 }}>
                      <img
                        width={140}
                        height={140}
                        src={GooglePlayQR}
                        alt='googleplay qr'
                      />
                    </Box>
                  ) : null}

                  <a href={GP_URL} target='_blank' rel='noreferrer'>
                    <img width={140} src={GooglePlay} alt='googleplay link' />
                  </a>
                </Stack>
                <Stack direction='column' alignItems='center'>
                  {deviceOs === "Other" ? (
                    <Box sx={{ marginBottom: 1 }}>
                      <img
                        width={140}
                        height={140}
                        src={AppleStoreQR}
                        alt='app store qr'
                      />
                    </Box>
                  ) : null}
                  <a href={APP_STORE_URL} target='_blank' rel='noreferrer'>
                    <img width={140} src={AppleStore} alt='app store link' />
                  </a>
                </Stack>
              </Stack>
            </Box>
            <Stack alignItems='center' justifyContent='center'>
              <Typography fontWeight='bold' textAlign='center'>
                or
              </Typography>
              <Button
                variant='text'
                sx={{ textDecoration: "underline" }}
                component={Link}
                to={
                  email || userEmail || user?.email
                    ? "/onboarding-form"
                    : "/add-email"
                }
              >
                Continue to Web Version
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </WidgetLayout>
  );
};
